 import React, { useState } from "react";
 import * as Components from './Components';
 import axios from "./api/axios";
 import Swal from "sweetalert2";
 import { SetOnLocalStorage, GetOnLocalStorage } from "./function/local_storage";
 import { FRONTEND_JQ } from "./constant/frontend";

 function App() {

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const [signIn, toggle] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            setLoading(true);

            const response = await axios.post('IsUsuarios/login', JSON.stringify({username: user, password: password}), {
                headers: {'Content-Type': 'application/json'}
            });

            Swal.fire({
                icon: 'success',
                title: 'Bienvenido',
                showConfirmButton: false,
                timer: 1500
            }).then(function() {
                window.location.replace(FRONTEND_JQ + `?usr=${response.data.Message}`);
            });

        } catch (error) {
            //"Error " + error.response.status, error.response.statusText,
            Swal.fire("Error", "Credenciales Erróneas", "error");
        }
        finally { setLoading(false); }
    }

    return(
        <div className="superContainer">
            <Components.Container>
            <Components.SignUpContainer signinIn={signIn}>
                <Components.Form>
                    <Components.Title> Registrarse </Components.Title>
                    <Components.Input type='text' placeholder='Usuario' />
                    <Components.Input type='email' placeholder='Email' />
                    <Components.Input type='password' placeholder='Contraseña' />
                    <Components.Button> Crear </Components.Button>
                </Components.Form>
            </Components.SignUpContainer>

            <Components.SignInContainer signinIn={signIn}>
                <Components.Form onSubmit={handleSubmit}>
                    <Components.Title>Sign in</Components.Title>
                    <Components.Input type='text' placeholder='Usuario' onChange={(e) => setUser(e.target.value)} required/>
                    <Components.Input type='password' placeholder='Contraseña' onChange={(e) => setPassword(e.target.value)} required/>
                    {/* <Components.Anchor href='#'>Forgot your password?</Components.Anchor> */}
                    <Components.Button type="submit">
                        {(loading)? <div className="lds-dual-ring"></div> : 'Login'}
                    </Components.Button>
                </Components.Form>
            </Components.SignInContainer>

            <Components.OverlayContainer signinIn={signIn}>
                <Components.Overlay signinIn={signIn}>

                <Components.LeftOverlayPanel signinIn={signIn}>
                    <Components.Title> INGESOFTWARE </Components.Title>
                    <Components.Paragraph>
                        {/* To keep connected with us please login with your personal info */}
                    </Components.Paragraph>
                    <Components.GhostButton onClick={() => toggle(true)}>
                        Sign In
                    </Components.GhostButton>
                    </Components.LeftOverlayPanel>

                    <Components.RightOverlayPanel signinIn={signIn}>
                    <Components.Title> INGESOFTWARE </Components.Title>
                    <Components.Paragraph>
                        {/* Enter Your personal details and start journey with us */}
                    </Components.Paragraph>
                        <Components.GhostButton onClick={() => toggle(false)}>
                            Sigin Up
                        </Components.GhostButton> 
                    </Components.RightOverlayPanel>

                </Components.Overlay>
            </Components.OverlayContainer>

            </Components.Container>
        </div>
    )
}

export default App;